// 引入依赖
import Vue from "vue";
import VueRouter from "vue-router";

/* Layout */
// import Layout from '@/layout'

// 使用路由中间件
Vue.use(VueRouter);

// 路由表
const routes = [{
        path: "/index",
        component: () =>
            import ("@/views/index"),
        meta: {
            title: "生态应用平台",
        },
    },
    {
        path: "/home",
        component: () =>
            import ("@/views/home"),
        meta: {
            title: "引导页",
        },
    },
    {
        path: "/yiqing",
        component: () =>
            import ("@/views/yiqing"),
        meta: {
            title: "疫情防控",
        },
    },
    {
        path: "/",
        component: () =>
            import ("@/views/down"),
        meta: {
            title: "下载",
        },
    },
    {
        path: "/article",
        component: () =>
            import ("@/views/article/index"),
        meta: {
            title: "详情",
        },
    },
    {
        path: "/articlelist",
        component: () =>
            import ("@/views/article/list"),
        meta: {
            title: "动态建设",
        },
    }
];

// 修改路由模式
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

//暴露
export default router;