import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios'
Vue.config.productionTip = false
 
//全局配置axios的请求根路径
axios.defaults.baseURL = '/'
/* 
全局配置axios——把 axios挂载到Vue.prototype 上：
优点：供每个.vue组件的实例直接使用
缺点：无法实现 API接口 的复用！！！
 */
// Vue.prototype.axios = axios
// 或 用 $http 装逼
Vue.prototype.$http = axios

Vue.use(Element);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
